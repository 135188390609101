import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { TableColumn } from 'src/app/models/utils';
import { localizePaginatorLabels } from 'src/app/shared/utils/table';
import { expandAndCollapseAnimation } from 'src/app/shared/utils/animations';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-advanced-table',
  templateUrl: './advanced-table.component.html',
  styleUrls: ['./advanced-table.component.scss'],
  animations: expandAndCollapseAnimation(),
  standalone: true,
  imports: [CommonModule, AppMaterialModule, DragDropModule],
})
export class AdvancedTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public dataSource = new MatTableDataSource([]);
  public displayedColumns: string[];
  public expandedRows: any[] = [];

  @Input() actions!: TemplateRef<any>;
  @Input() columnTemplates!: Record<string, TemplateRef<any>>;
  @Input() expandableRows!: TemplateRef<any>;
  @Input() dragableColumns = false;
  @Input() tableColumns: TableColumn[] = [];
  @Input() pageSizeOptions: number[] = [5, 10, 25, 50];

  @Output() expandRow: EventEmitter<{
    element: any;
    isExpanded: boolean;
  }> = new EventEmitter();
  @Input() isMultiTemplateDataRows = false;
  @Input() matSortActive: string | undefined;
  @Input() matSortDirection: 'asc' | 'desc' = 'asc';
  @Input() sortingDataAccessor: (
    data: any,
    sortHeaderId: string
  ) => string | number;

  @Input() set tableData(data: any[]) {
    this.setDataSource(data);
    this.dataSource.paginator ||= this.paginator;
    this.dataSource.sort ||= this.sort;
  }

  @Input() isLoading = false;

  constructor() {}

  ngOnInit(): void {
    const columnNames = this.tableColumns.map(
      (tableColumn: TableColumn) => tableColumn.name
    );
    (this.actions || this.expandableRows) && columnNames.push('actions');
    this.displayedColumns = columnNames;
  }

  ngAfterViewInit(): void {
    localizePaginatorLabels(this.paginator);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.sortingDataAccessor) {
      this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    }
  }

  setDataSource(data: any[]) {
    this.dataSource = new MatTableDataSource<typeof data>(data);
  }

  handleExpandRow(element: any) {
    if (this.expandedRows.includes(element)) {
      this.expandedRows = this.expandedRows.filter((el) => el !== element);
      this.expandRow.emit({ element, isExpanded: false });
    } else {
      this.expandedRows.push(element);
      this.expandRow.emit({ element, isExpanded: true });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.displayedColumns,
      event.previousIndex,
      event.currentIndex
    );
  }
}
