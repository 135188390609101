<div class="table-container">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    [matSortActive]="matSortActive"
    [matSortDirection]="matSortDirection"
    cdkDropList="dragableColumns"
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="drop($event)"
    [multiTemplateDataRows]="isMultiTemplateDataRows"
  >
    <ng-container
      *ngFor="let column of tableColumns"
      [matColumnDef]="column.name"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        cdkDrag
        [disabled]="!column.sortable"
        [mat-sort-header]="column.sortable ? column.name : null"
        [arrowPosition]="column.align === 'right' ? 'before' : 'after'"
      >
        {{ column.label }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="column.title ? column.title(row) : null"
        [ngClass]="{ 'text-right': column.align === 'right' }"
      >
        <ng-container
          *ngIf="
            columnTemplates && columnTemplates[column.name];
            else defaultColumn
          "
        >
          <ng.template
            [ngTemplateOutlet]="columnTemplates[column.name]"
            [ngTemplateOutletContext]="{ $implicit: row }"
          ></ng.template>
        </ng-container>
        <ng-template #defaultColumn>
          {{ column.cell(row) }}
        </ng-template>
      </td>
    </ng-container>
    <ng-container *ngIf="actions || expandableRows" matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="text-right">
        <div class="d-flex align-center justify-end">
          <ng.template
            [ngTemplateOutlet]="actions"
            [ngTemplateOutletContext]="{ $implicit: row }"
          ></ng.template>
          <button
            *ngIf="expandableRows"
            mat-icon-button
            (click)="handleExpandRow(row); $event.stopPropagation()"
          >
            @if (expandedRows.includes(row)) {
              <mat-icon color="accent">keyboard_arrow_up</mat-icon>
            } @else {
              <mat-icon color="accent">keyboard_arrow_down</mat-icon>
            }
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container *ngIf="expandableRows" matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let row"
        [attr.colspan]="displayedColumns.length"
      >
        <div
          class="element-detail"
          [@expandAndCollapse]="
            expandedRows.includes(row) ? 'expanded' : 'collapsed'
          "
        >
          <ng.template
            [ngTemplateOutlet]="expandableRows"
            [ngTemplateOutletContext]="{ $implicit: row }"
          ></ng.template>
        </div>
      </td>
    </ng-container>

    <ng-container *ngIf="!expandableRows">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </ng-container>

    <ng-container *ngIf="expandableRows">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="element-row"
        [class.expanded-row]="expandedRows.includes(row)"
        (click)="handleExpandRow(row); $event.stopPropagation()"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
      ></tr>
    </ng-container>
  </table>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
</div>
<mat-paginator
  [pageSizeOptions]="pageSizeOptions"
  aria-label="Seitennavigation"
  showFirstLastButtons
  [hidden]="paginator?.getNumberOfPages() <= 1"
></mat-paginator>
